import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
// import { images } from '../../constants'
import './About.scss';
import { urlFor, client } from '../../client';


const About = () => {
  
  const [abouts, setAbouts] = useState([]);

  const mounted = useRef(false);


  useEffect(() => {
    mounted.current = true
    const query = '*[_type == "abouts"]'

    client.fetch(query)
    .then((data) => {
      setAbouts(data);
    })
    return () => {
      mounted.current= false 
    }
  }, [])



  // useEffect(() => {

  //   const query = '*[_type == "abouts"]'

  //   client.fetch(query)
  //   .then((data) => {
  //     setAbouts(data);
  //   })

  // }, [])
  


  return (
    <>
      <h2 className="head-text">We know <span>Good Products</span> <br /> mean <span> Good Business</span> </h2>
      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale:  1.1 }}
            transition={{ duration: 1, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <h2 className="bold-text h2" style={{ marginTop: 20}}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10}}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);