import React from 'react';
import { motion } from 'framer-motion';



import { AppWrap } from '../../wrapper'
import { images } from '../../constants';
import './Header.scss';

const scaleVariant = {
  whileInView:{
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      durtation: 1,
      ease: 'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className="app__header app__flex">
      <motion.div 
        whileInView={{x : [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 1.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello and welcome to</p>
              <h1 className="head-text">Bexoni</h1>
            </div>
          </div>
          <div className="tag-cmp app__flex">
            <p className="p-text">Let's help with your</p>
            <p className="p-text"> Digital needs and wants such as </p>
          </div>
          <div className="tag-cmp app__flex">
            <p className="p-text">Websites, Campaigns </p>
            <p className="p-text"> Branding, Mobile Apps </p>
          </div>
        </div>
      </motion.div>

      <motion.div 
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 1.5, delayChildren: 0.5 }}
        className="app__header-img"
      >
        {/* <img src={images.profile} alt="profile_bg" /> */}
        <motion.img 
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
          src={images.hi}
          alt="profiles_circle"
          className="overlay_circle"
        />
      </motion.div>

      <motion.div
        variant={scaleVariant}
        whileInView={scaleVariant.whileInView}
        className="app__header-circles"
      >

          <div className="circle-cmp app__flex" >
          <div className="tag-cmp app__flex">
            <p className="p-text">Graphic Design, SEO </p> 
            <p className="p-text"> Branding, Mobile Apps </p>
          </div>
          
            {/* <img src={circle} alt="circle" /> */}
          </div>
          <div className="circle-cmp app__flex" >
          <div className="tag-cmp app__flex">
            <p className="p-text">Site Maintenance </p> 
            <p className="p-text"> Consultations and much more </p>
          </div>
          
            {/* <img src={circle} alt="circle" /> */}
          </div>
    

        {/* {[images.flutter, images.redux, images.sass].map((circle, index) => (
          <div className="circle-cmp app__flex" key={`circle-${index}`}>
            <img src={circle} alt="circle" />
          </div>
        ))} */}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'home');