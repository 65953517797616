import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const SocialMedia = () => {
  return (
    <div className="app__social">
        <div>
          <a href="https://twitter.com/_bexoni">
            <BsTwitter />
          </a>
        </div>
        <div>
          <a href="https://www.instagram.com/_bexoni/">
            <BsInstagram />
          </a>
        </div>
        <div>
          <a href="https://www.facebook.com/Bexoni-105216945444669">
            <FaFacebookF />
          </a>
        </div>
    </div>
  )
}

export default SocialMedia